import "./App.css";
import "@mantine/core/styles.css";
import {
  AppShell,
  Box,
  Burger,
  Center,
  Collapse,
  Divider,
  Drawer,
  Group,
  HoverCard,
  ScrollArea,
  UnstyledButton,
  rem,
  Text,
  ThemeIcon,
  Anchor,
  SimpleGrid,
  Menu,
} from "@mantine/core";
import { useEffect, useState } from 'react';
import { I18nextProvider, useTranslation } from "react-i18next";
import i18n from "./i18n";
import Logo from "./components/Logo/Logo";
import { useDisclosure } from "@mantine/hooks";
import { Link, scroller } from 'react-scroll';
import { LanguagePicker } from "./components/LanguagePicker/LanguagePicker";
import {
  IconChevronDown,
} from "@tabler/icons-react";
import classes from "./App.module.css";
import { LanguagePickerMobile } from "./components/LanguagePickerMobile/LanguagePickerMobile";
import BodyContent from "./components/BodyContent/BodyContent";
import LoginButton from "./components/LoginButton/LoginButton";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import ProfileContent from "./components/ProfileContent/ProfileContent";
import { FooterLinks } from "./components/FooterLinks/FooterLinks";
import { dynamicFeatureList } from "./components/FeatureCardList/FeatureCardList";
import { LanguageProvider } from './LanguageContext';
import { CartModal } from "./components/Cart/CartModal";
import { useSession } from "./auth/SessionContext";
import { DEBUG } from ".";
import tvlogo from "./media/accTV.png";
import acadlogo from "./media/drive-logo.png";
import irdlogo from "./media/iRdrive-logo.png";
export function getParamValue(searchParam: string, iterator: IterableIterator<string>): string {
  const arrayFromIterator = Array.from(iterator);
  return arrayFromIterator[arrayFromIterator.indexOf(searchParam)];
}
const scrollToAnchor = (id: string) => {
  scroller.scrollTo(id, {
    duration: 500,
    delay: 0,
    offset: -64, // Offset in Pixel, um den Header zu berücksichtigen
  });
};
const apps = [
  { name: "ACC TV", logo: tvlogo, url: "https://acctv.de" },
  { name: "iR DRIVE", logo: irdlogo, url: "https://irdrive.accsolutions.de" }
];
function App() {
  const { sessionData, loadSessionDataAsync, setSessionData } = useSession();
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorId, setAnchorId] = useState<string | null>(null);
  const { t } = useTranslation();
  const redirectToHome = (id: string) => {
    setAnchorId(id);
    navigate("/");
  };
  useEffect(() => {
    if (location.pathname === "/" && anchorId) {
      scrollToAnchor(anchorId);
      setAnchorId(null);  // Zurücksetzen der Anker-ID nach dem Scrollen
    }
  }, [location, anchorId]);

  const [opened] = useDisclosure();
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] =
    useDisclosure(false);
    
  const [linksOpened, { toggle: toggleLinks }] = useDisclosure(false);
  const links = dynamicFeatureList.features.map((feature) => (
      <UnstyledButton key={feature.id} onClick={() => { redirectToHome(feature.id); closeDrawer(); }} className={classes.subLink}>
        <Group wrap="nowrap" align="flex-start">
          <ThemeIcon size={34} variant="default" radius="md">
            <feature.icon
              style={{ width: rem(22), height: rem(22) }}
              color={"#66ae3f"}
            />
          </ThemeIcon>
          <div>
            <Text size="sm" fw={500}>
              {i18n.t(dynamicFeatureList.title.replace("<id>", feature.id))}
            </Text>
            <Text size="xs" c="dimmed">
              {i18n.t(dynamicFeatureList.description.replace("<id>", feature.id))}
            </Text>
          </div>
        </Group>
      </UnstyledButton>
  ));
  useEffect(() => {
    const loadSession = (async () => {
      await loadSessionDataAsync();
    });
    loadSession();
  }, []);
  useEffect(() => {
    if(DEBUG) console.log(`app logged_in status changed to: ${sessionData.logged_in}`);
    // Hier kannst du weitere Logik hinzufügen, die ausgeführt werden soll, wenn sich logged_in ändert
  }, [sessionData.logged_in]);
  useEffect(() => {
    console.log("sessionData updated:", sessionData);
  }, [sessionData]);
  return (
    <LanguageProvider>
      <div className="App">
        <AppShell
          header={{ height: 60 }}
          navbar={{
            width: "100%",
            breakpoint: "sm",
            collapsed: { desktop: true, mobile: !opened },
          }}>
          <AppShell.Header className={classes.headerOnTop}>
            <Group h="100%" px="md" justify="space-between" style={{ display: 'flex', alignItems: 'center' }}>
              <Group style={{ flex: 1 }}>
                <Menu>
                  <Menu.Target>
                    <UnstyledButton style={{ display: 'flex', alignItems: 'center' }}>
                      <Logo />
                      <IconChevronDown size={16} style={{ marginLeft: 8 }} /> {/* Der Pfeil-Indikator */}
                    </UnstyledButton>
                  </Menu.Target>

                  <Menu.Dropdown>
                    {apps.map((app) => (
                      <Menu.Item key={app.name} onClick={() => window.location.href = app.url}>
                        <Logo name={app.name} logo={app.logo} />
                      </Menu.Item>
                    ))}
                  </Menu.Dropdown>
                </Menu>
              </Group>
              <Group gap={10} style={{ flex: 1, justifyContent: 'center' }} visibleFrom="sm">
                <UnstyledButton onClick={() => { redirectToHome("home"); }}>
                  <UnstyledButton>{t("menu.home")}</UnstyledButton>
                </UnstyledButton>
                <HoverCard
                  width={600}
                  position="bottom"
                  radius="md"
                  shadow="md"
                  withinPortal
                >
                  <HoverCard.Target>
                    <UnstyledButton>
                      <Center inline>
                        <Box component="span">
                        {t("menu.features")}
                        </Box>
                        <IconChevronDown
                          style={{ width: rem(16), height: rem(16) }}
                          color={"#66ae3f"}
                        />
                      </Center>
                    </UnstyledButton>
                  </HoverCard.Target>
                  <HoverCard.Dropdown style={{ overflow: "hidden" }}>
                    <Group justify="space-between" px="md">
                      <Text fw={500}>{t("menu.features")}</Text>
                      <Anchor href="#features" fz="xs">
                        {t("menu.viewfeatures")}
                      </Anchor>
                    </Group>
                    <Divider my="sm" />
                    <SimpleGrid cols={2} spacing={0}>
                      {links}
                    </SimpleGrid>
                  </HoverCard.Dropdown>
                </HoverCard>
                <UnstyledButton onClick={() => { redirectToHome("community"); }}>
                  <UnstyledButton>{t("menu.community")}</UnstyledButton>
                </UnstyledButton>
              </Group>
              <Group style={{ flex: 1, justifyContent: 'flex-end' }} visibleFrom="sm">
                <LanguagePicker/>
                <LoginButton />
              </Group>
              <Burger
                opened={drawerOpened}
                onClick={toggleDrawer}
                hiddenFrom="sm"
              />
            </Group>
          </AppShell.Header>

          <Drawer
            opened={drawerOpened}
            onClose={closeDrawer}
            size="100%"
            padding="md"
            title={<Logo />}
            hiddenFrom="sm"
            zIndex={1000000}>
            <ScrollArea h={`calc(100vh - ${rem(80)})`} mx="-md">
              <Divider my="sm" />
              <Link to={"home"} onClick={() => { closeDrawer(); redirectToHome("home");}}>
                <Text className={classes.link}>{t("menu.home")}</Text>
              </Link>
              <UnstyledButton className={classes.link} onClick={toggleLinks}>
                <Center inline>
                  <Box component="span" mr={5}>
                    {t("menu.features")}
                    <IconChevronDown
                    style={{ width: rem(16), height: rem(16) }}
                    color={"#66ae3f"}
                  />
                  </Box>
                  
                </Center>
              </UnstyledButton>
              <Collapse in={linksOpened}>{links}</Collapse>
              <Link to={"community"} onClick={() => { closeDrawer(); redirectToHome("community");}}>
                <Text className={classes.link}>{t("menu.community")}</Text>
              </Link>
              <Divider my="sm" />
                <LoginButton />
              <Divider my="sm" />
              <LanguagePickerMobile />
            </ScrollArea>
          </Drawer>

          <AppShell.Main>
            <I18nextProvider i18n={i18n}>
              <CartModal logged_in={sessionData.logged_in} />
              <Routes>
                <Route path="*" element={<BodyContent logged_in={sessionData.logged_in} />} />
                <Route path="/account" element={<ProfileContent />} />
              </Routes>
              <FooterLinks />
            </I18nextProvider>
          </AppShell.Main>
        </AppShell>
      </div>
    </LanguageProvider>
  );
}

export default App;
