import { DEBUG } from "..";

export interface UserData {
  logged_in: boolean;
  steam_id: string;
  avatar: string;
  name: string;
  la: number;
  cr: number;
}


export function LoadSessionData(updateSessionData: (data: UserData) => void){
  fetch('/dyn/session.php?mode=get', {
    method: 'GET',
    credentials: 'include' // Dies sendet die Cookies mit
  })
  .then(response => response.json())
  .then(data => { updateSessionData(data); if(DEBUG) console.log("Loaded: " + data); })
  .catch(error => { if(DEBUG) console.error('Error:', error) });
}

export async function LoadSessionDataAsync(): Promise<UserData>{
  if(!DEBUG)
    return fetch('/dyn/session.php?mode=get', {
        method: 'GET',
        credentials: 'include' // Dies sendet die Cookies mit
      })
      .then(response => { if(DEBUG) console.log("Response", response); return response.json(); })
      .catch(error => { if(DEBUG) console.error('Error:', error) });
  else{
    const debugData = `{"logged_in":true,"name":"Loki","avatar":"https://avatars.steamstatic.com/b863b6971df52f41b9e36a6901bb883c71258908_medium.jpg","steam_id":"76561197985207199","la":1736187182,"cr":1736185434}`;
    console.log(debugData);
    return JSON.parse(debugData) as UserData;
  }
}

export function DeleteSession(updateSessionData: (data: UserData) => void){
  fetch('/dyn/session.php?mode=logout', {
    method: 'GET',
    credentials: 'include' // Dies sendet die Cookies mit
  })
  .then(response => response.json())
  .then(data => updateSessionData(data))
  .catch(error => { if(DEBUG) console.error('Error:', error) });
}

export async function DeleteSessionAsync(): Promise<UserData>{
  return fetch('/dyn/session.php?mode=logout', {
    method: 'GET',
    credentials: 'include' // Dies sendet die Cookies mit
  })
  .then(response => response.json())
  .catch(error => { if(DEBUG) console.error('Error:', error) });
}
export {};